@use '@/styles/rtl.module';
@import '@/styles/breakpoints.module';

.container {
  //--swiper-wrapper-transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  --shadow-width: 10px;
  --gradient-direction: to right;
  position: relative;
  padding: 0;
  max-width: 1440px;
  margin-inline: auto;
  overflow: hidden;

  [dir='rtl'] & {
    --gradient-direction: to left;
  }

  &::before,
  &::after {
    background: linear-gradient(var(--gradient-direction), rgba(10, 10, 10, 1), rgba(10, 10, 10, 0));

    content: '';
    display: block;
    position: absolute;
    top: 0;
    line-height: 0;
    width: var(--shadow-width);
    height: 100%;
    z-index: 3;
    pointer-events: none;
  }

  &::before {
    inset-inline-start: -2px;
  }

  &::after {
    inset-inline-end: -2px;
    transform: rotate(180deg);
  }

  @include tablet {
    --shadow-width: 90px;
  }
  @include desktop {
    --shadow-width: 190px;
  }
}

.slide.slide {
  height: 316px;

  @include tablet {
    height: 294px;
    width: 555px;
  }
}

.navigation {
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  @include tablet {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.navigationContainer {
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  width: 100%;
  margin-inline: 40px;
}

.prevSlide,
.nextSlide {
  position: relative;
  z-index: 10;
  margin: 0;

  &:after {
    content: '';
  }
}
