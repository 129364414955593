@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
@import '@/styles/rtl.module';

.promoCard {
  padding: 36px 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  opacity: 0.4;
  transition: opacity 0.4s ease;

  a {
    user-select: auto;
  }

  &.active {
    opacity: 1;
  }

  &:before {
    @extend .dirDependent;
    content: '';
    border-radius: var(--palette-border-radius-m);
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: var(--color-card-bg);
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
    @include tablet {
      mask-position: center;
      mask-image: url('promo-card-shape-mask.svg');
    }
  }

  @include tablet {
    flex-direction: row;
    text-align: start;
    padding: 0 32px;
    justify-content: space-between;
    position: relative;
  }

  .promoCardTitle {
    margin-bottom: 12px;
    @include tablet {
      margin-bottom: 12px;
    }
  }

  .promoCardContent {
    width: 100%;
    @include tablet {
      max-width: 308px;
    }
  }

  .promoCardSubtitle {
    margin-bottom: 16px;
    @include tablet {
      margin-bottom: 24px;
    }
  }

  .promoCardBonus {
    flex-shrink: 0;
  }

  .promoCardCtaContainer {
    & > * {
      width: 100%;
      @include tablet {
        width: auto;
      }
    }

    margin-bottom: 16px;
    @include tablet {
      margin-bottom: 0;
    }
  }

  .promoCardFootnote {
    margin-top: 12px;
    @include tablet {
      margin-top: 22px;
    }
  }
}
